const formatAbsoluteNumber = (number: number | null, decimals?: number) => {
  let absNumber = number != null ? Math.max(number, 0) : null;
  return absNumber != null && absNumber != 0
    ? Math.max(absNumber, 0).toLocaleString(undefined, {
        maximumFractionDigits: decimals || 0,
      })
    : "-";
};

export default formatAbsoluteNumber;
