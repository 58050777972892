import CheckIcon from "@mui/icons-material/Check";
import ComputerIcon from "@mui/icons-material/Computer";
import Edit from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PersonIcon from "@mui/icons-material/Person";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { Tooltip } from "@mui/material";
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { add, format, max } from "date-fns";
import isToday from "date-fns/isToday";
import sumBy from "lodash/sumBy";
import React, { Fragment } from "react";
import { useFeatureFlagsContext } from "../../../contexts/FeatureFlagsContext";
import formatNumber from "../../../lib/formatNumber";
import { dateAsUTC } from "../../../lib/getForecastDataRecords";
import { ICombinedRecord } from "../../../types";
import DailyEditedCell from "./DailyEditedCell";
import ForecastDataGrid from "./ForecastDataGrid";
import { useSearchParams } from "react-router-dom";
import { useDateFromParams } from "../../../hooks/useDateFromParams/useDateFromParams";
import { OpeningHoursCell } from "./OpeningHoursCell";
import formatAbsoluteNumber  from "../../../lib/formatAbsoluteNumber";

const DailyDataGrid = ({
  rows,
  openNewFiguresDialog,
  openAdjustmentDialog,
  openVarianceDialog,
}: {
  rows: ICombinedRecord[];
  openNewFiguresDialog: ({ row }: GridRowParams) => void;
  openAdjustmentDialog: ({ row }: GridRowParams) => void;
  openVarianceDialog: ({ row }: GridRowParams) => void;
}) => {
  const columnDefinition: GridColDef[] = [
    {
      field: "id",
    },
    {
      field: "forecastId",
    },
    {
      field: "pendingActions",
      type: "actions",
      width: 20,
      getActions: (params: GridRowParams) => {
        const showAlert =
          params.row.businessDate > new Date() && params.row.showAlert;
        return [
          <GridActionsCellItem
            disabled={!showAlert}
            key={params.id}
            icon={
              showAlert ? (
                <Tooltip title="Review new suggested forecast">
                  <ErrorOutlineIcon />
                </Tooltip>
              ) : (
                <span></span>
              )
            }
            label="Action on Forecast"
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              openNewFiguresDialog(params);
            }}
          />,
        ];
      },
    },
    {
      field: "formattedBusinessDate",
      headerName: "Date",
      width: 140,
    },
    {
      field: "businessDate",
      headerName: "Raw-Date",
    },
    {
      field: "eatIn",
      headerName: "Original-Eat-In",
      type: "number",
    },
    {
      field: "delivery",
      headerName: "Original-Delivery",
      type: "number",
    },
    {
      field: "collect",
      headerName: "Original-Collect",
      type: "number",
    },
    {
      field: "total",
      headerName: "Original-Total",
      type: "number",
    },
    {
      field: "multiplier",
      headerName: "Multiplier",
      type: "number",
    },
    {
      field: "adjustmentId",
      headerName: "adjustmentId",
      type: "number",
    },
    {
      field: "adjustment_reasonId",
      headerName: "adjustment_reasonId",
      type: "number",
    },
    {
      field: "adjustment_otherReason",
      headerName: "adjustment_otherReason",
    },
    {
      field: "adjustment_createdAt",
      headerName: "createdAt",
    },
    {
      field: "adjustment_createdBy",
      headerName: "createdBy",
    },
    {
      field: "totalEatIn",
      headerName: "Eat-In",
      type: "number",
      renderCell: ({ row }) => {
        const { eatIn, multiplier, eatInOffset, isTotalRow } = row;

        if (isTotalRow) {
          return formatNumber(eatIn);
        } else {
          return formatAbsoluteNumber(eatIn * multiplier + eatInOffset);
        }
      },
      description: "Eat-In forecast",
    },
    {
      field: "eatInOffset",
      headerName: "Eat-In Offset",
      type: "number",
      valueGetter: ({ row }) => formatNumber(row.eatInOffset),
    },
    {
      field: "totalDelivery",
      headerName: "Delivery",
      type: "number",
      renderCell: ({ row }) => {
        const { delivery, multiplier, deliveryOffset, isTotalRow } = row;

        if (isTotalRow) {
          return formatNumber(delivery);
        } else {
          return formatNumber(delivery * multiplier + deliveryOffset);
        }
      },
      description: "Delivery forecast",
    },
    {
      field: "deliveryOffset",
      headerName: "Delivery Offset",
      type: "number",
      valueGetter: ({ row }) => formatNumber(row.deliveryOffset),
    },
    {
      field: "totalCollect",
      headerName: "Collect",
      type: "number",
      renderCell: ({ row }) => {
        const { collect, multiplier, collectOffset, isTotalRow } = row;
        if (isTotalRow) {
          return formatNumber(collect);
        } else {
          return formatNumber(collect * multiplier + collectOffset);
        }
      },
      description: "Collect forecast",
    },
    {
      field: "collectOffset",
      headerName: "Collect Offset",
      type: "number",
      valueGetter: ({ row }) => formatNumber(row.collectOffset),
    },
    {
      field: "totalAdjusted",
      headerName: "Total",
      type: "number",
      renderCell: ({ row }) => {
        const { total, multiplier, totalOffset, isTotalRow } = row;
        if (isTotalRow) {
          return formatNumber(total);
        }

        return (
          <DailyEditedCell
            value={total}
            offset={totalOffset}
            multiplier={multiplier}
          />
        );
      },
      description: "Total forecast",
    },
    {
      field: "totalOffset",
      headerName: "Total Offset",
      type: "number",
      valueGetter: ({ row }) => formatNumber(row.totalOffset),
      description: "Total forecast",
    },
    {
      field: "actual_total",
      headerName: "Actual",
      type: "number",
      valueGetter: ({ row }) => formatNumber(row.actual_total),
      description: "Actual sales",
    },
    {
      field: "fk_forecastId",
      headerName: "fk_forecastId",
      type: "number",
    },
    {
      field: "latest_forecastId",
      headerName: "latest_forecastId",
      type: "number",
      description: "The latest total from PI",
    },
    {
      field: "latest_total",
      headerName: "PI Latest Total",
      type: "number",
      description: "The latest total from PI",
    },
    {
      field: "Actions",
      // headerName: "Adjust",
      type: "actions",
      width: 20,
      getActions: (params: GridRowParams) => {
        // if Total row, don't show actions
        if (params.row.isTotalRow) {
          return [];
        }
        if (params.row.total === 0) {
          return [];
        }
        return [
          params.row.businessDate >= dateAsUTC(new Date()) ? (
            <GridActionsCellItem
              key={params.id}
              icon={
                <Tooltip title="Forecast validation">
                  <Edit />
                </Tooltip>
              }
              label="Action on Forecast"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                openAdjustmentDialog(params);
              }}
            />
          ) : (
            <GridActionsCellItem
              color={
                params.row.variance_reasonId !== "" ? "success" : "default"
              }
              disabled={isToday(params.row.businessDate)}
              key={params.id}
              icon={
                <Tooltip title="Sales variance">
                  <PublishedWithChangesIcon />
                </Tooltip>
              }
              label="SALES VARIANCE"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                openVarianceDialog(params);
              }}
            />
          ),
        ];
      },
    },
    {
      field: "openingHours",
      headerName: "Opening Hours",
      width: 150,
      headerAlign: "right",
      align: "right",
      renderCell: ({ row }) => {
        if (row.isTotalRow) return;
        return <OpeningHoursCell row={row} />;
      },
    },

    {
      field: "indicator",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: ({ row }) => {
        if (row.isTotalRow) {
          return <></>;
        }

        if (row.totalOffset !== 0) {
          return (
            <Fragment>
              <PersonIcon />
              <CheckIcon />
            </Fragment>
          );
        }

        return row.adjustment_createdBy === "system" ? (
          <Fragment>
            <ComputerIcon />
            <PendingActionsIcon />
          </Fragment>
        ) : row.multiplier === 1 ? (
          <Fragment>
            <ComputerIcon />
            <CheckIcon />
          </Fragment>
        ) : (
          <Fragment>
            <PersonIcon />
            <CheckIcon />
          </Fragment>
        );
      },
      description: "Indicates status of a particular forecast",
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const { userChosenDate } = useDateFromParams({ searchParams });

  const { isBetaUser } = useFeatureFlagsContext();

  const calculateTotalRow = () => {
    if (rows.length === 0)
      return { id: 999, isTotalRow: true, showAlert: false };
    const totalEatIn = sumBy(rows, (row: ICombinedRecord) => {
      return row.eatIn * row.multiplier + row.eatInOffset;
    });
    const totalCollect = sumBy(rows, (row: ICombinedRecord) => {
      return row.collect * row.multiplier + row.collectOffset;
    });
    const totalDelivery = sumBy(rows, (row: ICombinedRecord) => {
      return row.delivery * row.multiplier + row.deliveryOffset;
    });
    const totalActual = sumBy(rows, (row: ICombinedRecord) => {
      return row.actual_total;
    });

    const businessDates = rows.map((row: ICombinedRecord) => row.businessDate);

    const latestDate = add(max(businessDates), { days: 1 });

    return {
      id: rows.length + 1,
      collect: totalCollect,
      delivery: totalDelivery,
      eatIn: totalEatIn,
      total: totalCollect + totalDelivery + totalEatIn,
      actual_total: totalActual,
      isTotalRow: true,
      showAlert: false,
      formattedBusinessDate: "Total",
      businessDate: latestDate,
    };
  };

  const totalRow = calculateTotalRow();

  const handleRowClick = ({ row }: { row: ICombinedRecord }) => {
    if (row.isTotalRow) return;

    const businessDate = row.businessDate;
    setSearchParams((prevParams) => {
      prevParams.set("date", format(businessDate, "yyyy-MM-dd"));
      return prevParams;
    });
  };

  return (
    <ForecastDataGrid
      columns={columnDefinition}
      rows={[totalRow, ...rows]}
      lastUpdated={new Date()}
      onRowClick={handleRowClick}
      getRowClassName={(params: GridRowParams) => {
        if (params.row.isTotalRow) return "total";
        else if (
          params.row.businessDate.toDateString() ===
          userChosenDate.toDateString()
        )
          return "selectedDate";
        else return "";
      }}
      disableColumnMenu={!isBetaUser}
      hideFooter={true}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
            forecastId: false,
            adjustmentId: false,
            adjustment_reasonId: false,
            multiplier: false,
            total: false,
            collect: false,
            delivery: false,
            eatIn: false,
            latest_total: false,
            latest_forecastId: false,
            fk_forecastId: false,
            adjustment_createdBy: false,
            adjustment_createdAt: false,
            adjustment_otherReason: false,
            businessDate: false,
            collectOffset: false,
            deliveryOffset: false,
            eatInOffset: false,
            totalOffset: false,
          },
        },
      }}
    />
  );
};

export default DailyDataGrid;
